import * as l from "react";
import "react-dom";
import { j as u } from "./jsx-runtime-D0AQ1Mn7.js";
function p(e, n) {
  if (typeof e == "function")
    return e(n);
  e != null && (e.current = n);
}
function m(...e) {
  return (n) => {
    let r = !1;
    const i = e.map((t) => {
      const o = p(t, n);
      return !r && typeof o == "function" && (r = !0), o;
    });
    if (r)
      return () => {
        for (let t = 0; t < i.length; t++) {
          const o = i[t];
          typeof o == "function" ? o() : p(e[t], null);
        }
      };
  };
}
var d = l.forwardRef((e, n) => {
  const { children: r, ...i } = e, t = l.Children.toArray(r), o = t.find(h);
  if (o) {
    const s = o.props.children, a = t.map((c) => c === o ? l.Children.count(s) > 1 ? l.Children.only(null) : l.isValidElement(s) ? s.props.children : null : c);
    return /* @__PURE__ */ u.jsx(f, { ...i, ref: n, children: l.isValidElement(s) ? l.cloneElement(s, void 0, a) : null });
  }
  return /* @__PURE__ */ u.jsx(f, { ...i, ref: n, children: r });
});
d.displayName = "Slot";
var f = l.forwardRef((e, n) => {
  const { children: r, ...i } = e;
  if (l.isValidElement(r)) {
    const t = w(r);
    return l.cloneElement(r, {
      ...g(i, r.props),
      // @ts-ignore
      ref: n ? m(n, t) : t
    });
  }
  return l.Children.count(r) > 1 ? l.Children.only(null) : null;
});
f.displayName = "SlotClone";
var y = ({ children: e }) => /* @__PURE__ */ u.jsx(u.Fragment, { children: e });
function h(e) {
  return l.isValidElement(e) && e.type === y;
}
function g(e, n) {
  const r = { ...n };
  for (const i in n) {
    const t = e[i], o = n[i];
    /^on[A-Z]/.test(i) ? t && o ? r[i] = (...a) => {
      o(...a), t(...a);
    } : t && (r[i] = t) : i === "style" ? r[i] = { ...t, ...o } : i === "className" && (r[i] = [t, o].filter(Boolean).join(" "));
  }
  return { ...e, ...r };
}
function w(e) {
  var i, t;
  let n = (i = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : i.get, r = n && "isReactWarning" in n && n.isReactWarning;
  return r ? e.ref : (n = (t = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : t.get, r = n && "isReactWarning" in n && n.isReactWarning, r ? e.props.ref : e.props.ref || e.ref);
}
var v = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], R = v.reduce((e, n) => {
  const r = l.forwardRef((i, t) => {
    const { asChild: o, ...s } = i, a = o ? d : n;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ u.jsx(a, { ...s, ref: t });
  });
  return r.displayName = `Primitive.${n}`, { ...e, [n]: r };
}, {}), C = "VisuallyHidden", E = l.forwardRef(
  (e, n) => /* @__PURE__ */ u.jsx(
    R.span,
    {
      ...e,
      ref: n,
      style: {
        // See: https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
        position: "absolute",
        border: 0,
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        wordWrap: "normal",
        ...e.style
      }
    }
  )
);
E.displayName = C;
export {
  E as V
};
