import { j as c } from "./jsx-runtime-D0AQ1Mn7.js";
import * as a from "react";
import "react-dom";
import { c as v } from "./utils-CJ9afRe1.js";
function u(r, e) {
  if (typeof r == "function")
    return r(e);
  r != null && (r.current = e);
}
function g(...r) {
  return (e) => {
    let n = !1;
    const o = r.map((t) => {
      const i = u(t, e);
      return !n && typeof i == "function" && (n = !0), i;
    });
    if (n)
      return () => {
        for (let t = 0; t < o.length; t++) {
          const i = o[t];
          typeof i == "function" ? i() : u(r[t], null);
        }
      };
  };
}
var m = a.forwardRef((r, e) => {
  const { children: n, ...o } = r, t = a.Children.toArray(n), i = t.find(R);
  if (i) {
    const l = i.props.children, s = t.map((p) => p === i ? a.Children.count(l) > 1 ? a.Children.only(null) : a.isValidElement(l) ? l.props.children : null : p);
    return /* @__PURE__ */ c.jsx(f, { ...o, ref: e, children: a.isValidElement(l) ? a.cloneElement(l, void 0, s) : null });
  }
  return /* @__PURE__ */ c.jsx(f, { ...o, ref: e, children: n });
});
m.displayName = "Slot";
var f = a.forwardRef((r, e) => {
  const { children: n, ...o } = r;
  if (a.isValidElement(n)) {
    const t = w(n), i = E(o, n.props);
    return n.type !== a.Fragment && (i.ref = e ? g(e, t) : t), a.cloneElement(n, i);
  }
  return a.Children.count(n) > 1 ? a.Children.only(null) : null;
});
f.displayName = "SlotClone";
var N = ({ children: r }) => /* @__PURE__ */ c.jsx(c.Fragment, { children: r });
function R(r) {
  return a.isValidElement(r) && r.type === N;
}
function E(r, e) {
  const n = { ...e };
  for (const o in e) {
    const t = r[o], i = e[o];
    /^on[A-Z]/.test(o) ? t && i ? n[o] = (...s) => {
      i(...s), t(...s);
    } : t && (n[o] = t) : o === "style" ? n[o] = { ...t, ...i } : o === "className" && (n[o] = [t, i].filter(Boolean).join(" "));
  }
  return { ...r, ...n };
}
function w(r) {
  var o, t;
  let e = (o = Object.getOwnPropertyDescriptor(r.props, "ref")) == null ? void 0 : o.get, n = e && "isReactWarning" in e && e.isReactWarning;
  return n ? r.ref : (e = (t = Object.getOwnPropertyDescriptor(r, "ref")) == null ? void 0 : t.get, n = e && "isReactWarning" in e && e.isReactWarning, n ? r.props.ref : r.props.ref || r.ref);
}
var S = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], x = S.reduce((r, e) => {
  const n = a.forwardRef((o, t) => {
    const { asChild: i, ...l } = o, s = i ? m : e;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ c.jsx(s, { ...l, ref: t });
  });
  return n.displayName = `Primitive.${e}`, { ...r, [e]: n };
}, {}), O = "Separator", d = "horizontal", j = ["horizontal", "vertical"], h = a.forwardRef((r, e) => {
  const { decorative: n, orientation: o = d, ...t } = r, i = C(o) ? o : d, s = n ? { role: "none" } : { "aria-orientation": i === "vertical" ? i : void 0, role: "separator" };
  return /* @__PURE__ */ c.jsx(
    x.div,
    {
      "data-orientation": i,
      ...s,
      ...t,
      ref: e
    }
  );
});
h.displayName = O;
function C(r) {
  return j.includes(r);
}
var y = h;
const P = a.forwardRef(
  ({ className: r, orientation: e = "horizontal", decorative: n = !0, ...o }, t) => /* @__PURE__ */ c.jsx(
    y,
    {
      ref: t,
      decorative: n,
      orientation: e,
      className: v(
        "shrink-0 bg-gray-neutral-80",
        e === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        r
      ),
      ...o
    }
  )
);
P.displayName = y.displayName;
export {
  P as S
};
