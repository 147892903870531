import Tooltip from 'components/dist/atoms/Tooltip';
import { forwardRef } from 'react';

interface MysherpasTooltipProps {
    title: React.ReactNode;
    hidden?: boolean;
    delayDuration?: number;
    align?: "center" | "start" | "end";
    asChild?: boolean;
    side?: "left" | "right" | "bottom" | "top";
}

export const MysherpasTooltip = forwardRef<HTMLButtonElement, React.PropsWithChildren<MysherpasTooltipProps>>((props, ref) => {
    const { title, align = 'start', side = "bottom", delayDuration = 700, children, asChild = true } = props;

    if (props.hidden) return <>{children}</>

    return <Tooltip delayDuration={delayDuration}>
        <Tooltip.Trigger
            aria-label={typeof title === 'string' ? title : undefined}
            asChild={asChild}
            ref={ref}>
            {props.children}
        </Tooltip.Trigger>
        <Tooltip.Portal>
            <Tooltip.Content
                align={align}
                side={side}
                alignOffset={0}
                className="max-w-md text-xs z-splash">
                {title}
            </Tooltip.Content>
        </Tooltip.Portal>
    </Tooltip>
});

MysherpasTooltip.displayName = 'MysherpasTooltip';