import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { T as p, O as f, C as O, a as P, D as x, R as k, b as $, P as g } from "./index-CG-RslGb.js";
import { V as u } from "./index-PuJQeDsA.js";
import * as i from "react";
import { c as o, g as v } from "./utils-CJ9afRe1.js";
const z = k, y = p;
y.displayName = p.displayName;
const d = ({ ...a }) => /* @__PURE__ */ e.jsx(g, { ...a });
d.displayName = g.displayName;
const N = i.forwardRef(({ className: a, ...t }, s) => /* @__PURE__ */ e.jsx(
  f,
  {
    ref: s,
    className: o(
      "fixed inset-0 z-dialog bg-black-primary/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      a
    ),
    ...t
  }
));
N.displayName = f.displayName;
const j = i.forwardRef(({ className: a, children: t, overlayClassName: s, withoutPortal: T, ...w }, R) => {
  const C = v(t, "DialogTitle"), n = /* @__PURE__ */ e.jsx(N, { className: s, onWheel: (l) => l.stopPropagation(), children: /* @__PURE__ */ e.jsxs(
    O,
    {
      onInteractOutside: (l) => {
        var c, m;
        (((c = l.target) == null ? void 0 : c.closest("[role='alert']")) ?? ((m = l.target) == null ? void 0 : m.closest("[data-toast='true']"))) && l.preventDefault();
      },
      ref: R,
      className: o(
        "fixed sm:max-h-[95%] left-[50%] top-[50%] z-dialog flex flex-col w-full sm:max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
        a
      ),
      ...w,
      children: [
        t,
        /* @__PURE__ */ e.jsxs(u, { children: [
          /* @__PURE__ */ e.jsx(H.Description, { children: "Press ESC to close" }),
          !C && /* @__PURE__ */ e.jsx(r, { children: "Dialog" })
        ] })
      ]
    }
  ) });
  return T ? n : /* @__PURE__ */ e.jsx(d, { children: n });
});
j.displayName = "DialogContent";
const D = ({
  className: a,
  ...t
}) => /* @__PURE__ */ e.jsx(
  "div",
  {
    className: o(
      "flex flex-col py-3 px-4 text-center sm:text-left",
      a
    ),
    ...t
  }
);
D.displayName = "Header";
const b = ({
  className: a,
  hideDivider: t,
  ...s
}) => /* @__PURE__ */ e.jsx(
  "div",
  {
    className: o(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 px-3 py-2",
      {
        "border-t": !t
      },
      a
    ),
    ...s
  }
);
b.displayName = "Footer";
const r = i.forwardRef(({ className: a, ...t }, s) => /* @__PURE__ */ e.jsx(
  P,
  {
    ref: s,
    className: o(
      "text-black- py-2 px-3.5 text-sm font-medium leading-none tracking-tight",
      a
    ),
    ...t
  }
));
r.displayName = "DialogTitle";
const h = i.forwardRef(({ className: a, ...t }, s) => /* @__PURE__ */ e.jsx(
  x,
  {
    ref: s,
    className: o("px-3", a),
    ...t
  }
));
h.displayName = x.displayName;
const H = Object.assign(z, {
  Content: j,
  Description: h,
  Footer: b,
  Header: D,
  Title: r,
  Trigger: y,
  Portal: d,
  Close: $,
  VisuallyHidden: u
});
export {
  H as D
};
