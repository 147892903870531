import * as a from "react";
import { useState as nt } from "react";
import { j as m } from "./jsx-runtime-D0AQ1Mn7.js";
import * as rt from "react-dom";
import ot from "react-dom";
import { a as T, _ as Re, b as at } from "./tslib.es6-CR4a2p76.js";
import { g as it, h as st } from "./index-pK5bN1zh.js";
function A(e, t, { checkForDefaultPrevented: n = !0 } = {}) {
  return function(o) {
    if (e == null || e(o), n === !1 || !o.defaultPrevented)
      return t == null ? void 0 : t(o);
  };
}
function ue(e, t) {
  if (typeof e == "function")
    return e(t);
  e != null && (e.current = t);
}
function Pe(...e) {
  return (t) => {
    let n = !1;
    const r = e.map((o) => {
      const i = ue(o, t);
      return !n && typeof i == "function" && (n = !0), i;
    });
    if (n)
      return () => {
        for (let o = 0; o < r.length; o++) {
          const i = r[o];
          typeof i == "function" ? i() : ue(e[o], null);
        }
      };
  };
}
function I(...e) {
  return a.useCallback(Pe(...e), e);
}
function ct(e, t) {
  const n = a.createContext(t), r = (i) => {
    const { children: c, ...s } = i, d = a.useMemo(() => s, Object.values(s));
    return /* @__PURE__ */ m.jsx(n.Provider, { value: d, children: c });
  };
  r.displayName = e + "Provider";
  function o(i) {
    const c = a.useContext(n);
    if (c) return c;
    if (t !== void 0) return t;
    throw new Error(`\`${i}\` must be used within \`${e}\``);
  }
  return [r, o];
}
function ut(e, t = []) {
  let n = [];
  function r(i, c) {
    const s = a.createContext(c), d = n.length;
    n = [...n, c];
    const l = (h) => {
      var p;
      const { scope: E, children: g, ...w } = h, u = ((p = E == null ? void 0 : E[e]) == null ? void 0 : p[d]) || s, v = a.useMemo(() => w, Object.values(w));
      return /* @__PURE__ */ m.jsx(u.Provider, { value: v, children: g });
    };
    l.displayName = i + "Provider";
    function f(h, E) {
      var u;
      const g = ((u = E == null ? void 0 : E[e]) == null ? void 0 : u[d]) || s, w = a.useContext(g);
      if (w) return w;
      if (c !== void 0) return c;
      throw new Error(`\`${h}\` must be used within \`${i}\``);
    }
    return [l, f];
  }
  const o = () => {
    const i = n.map((c) => a.createContext(c));
    return function(s) {
      const d = (s == null ? void 0 : s[e]) || i;
      return a.useMemo(
        () => ({ [`__scope${e}`]: { ...s, [e]: d } }),
        [s, d]
      );
    };
  };
  return o.scopeName = e, [r, lt(o, ...t)];
}
function lt(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((o) => ({
      useScope: o(),
      scopeName: o.scopeName
    }));
    return function(i) {
      const c = r.reduce((s, { useScope: d, scopeName: l }) => {
        const h = d(i)[`__scope${l}`];
        return { ...s, ...h };
      }, {});
      return a.useMemo(() => ({ [`__scope${t.scopeName}`]: c }), [c]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
var dt = globalThis != null && globalThis.document ? a.useLayoutEffect : () => {
}, ft = a.useId || (() => {
}), vt = 0;
function H(e) {
  const [t, n] = a.useState(ft());
  return dt(() => {
    n((r) => r ?? String(vt++));
  }, [e]), e || (t ? `radix-${t}` : "");
}
function Ne(e) {
  const t = a.useRef(e);
  return a.useEffect(() => {
    t.current = e;
  }), a.useMemo(() => (...n) => {
    var r;
    return (r = t.current) == null ? void 0 : r.call(t, ...n);
  }, []);
}
function mt({
  prop: e,
  defaultProp: t,
  onChange: n = () => {
  }
}) {
  const [r, o] = ht({ defaultProp: t, onChange: n }), i = e !== void 0, c = i ? e : r, s = Ne(n), d = a.useCallback(
    (l) => {
      if (i) {
        const h = typeof l == "function" ? l(e) : l;
        h !== e && s(h);
      } else
        o(l);
    },
    [i, e, o, s]
  );
  return [c, d];
}
function ht({
  defaultProp: e,
  onChange: t
}) {
  const n = a.useState(e), [r] = n, o = a.useRef(r), i = Ne(t);
  return a.useEffect(() => {
    o.current !== r && (i(r), o.current = r);
  }, [r, o, i]), n;
}
var re = a.forwardRef((e, t) => {
  const { children: n, ...r } = e, o = a.Children.toArray(n), i = o.find(pt);
  if (i) {
    const c = i.props.children, s = o.map((d) => d === i ? a.Children.count(c) > 1 ? a.Children.only(null) : a.isValidElement(c) ? c.props.children : null : d);
    return /* @__PURE__ */ m.jsx(ee, { ...r, ref: t, children: a.isValidElement(c) ? a.cloneElement(c, void 0, s) : null });
  }
  return /* @__PURE__ */ m.jsx(ee, { ...r, ref: t, children: n });
});
re.displayName = "Slot";
var ee = a.forwardRef((e, t) => {
  const { children: n, ...r } = e;
  if (a.isValidElement(n)) {
    const o = yt(n);
    return a.cloneElement(n, {
      ...Et(r, n.props),
      // @ts-ignore
      ref: t ? Pe(t, o) : o
    });
  }
  return a.Children.count(n) > 1 ? a.Children.only(null) : null;
});
ee.displayName = "SlotClone";
var gt = ({ children: e }) => /* @__PURE__ */ m.jsx(m.Fragment, { children: e });
function pt(e) {
  return a.isValidElement(e) && e.type === gt;
}
function Et(e, t) {
  const n = { ...t };
  for (const r in t) {
    const o = e[r], i = t[r];
    /^on[A-Z]/.test(r) ? o && i ? n[r] = (...s) => {
      i(...s), o(...s);
    } : o && (n[r] = o) : r === "style" ? n[r] = { ...o, ...i } : r === "className" && (n[r] = [o, i].filter(Boolean).join(" "));
  }
  return { ...e, ...n };
}
function yt(e) {
  var r, o;
  let t = (r = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : r.get, n = t && "isReactWarning" in t && t.isReactWarning;
  return n ? e.ref : (t = (o = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : o.get, n = t && "isReactWarning" in t && t.isReactWarning, n ? e.props.ref : e.props.ref || e.ref);
}
var bt = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], x = bt.reduce((e, t) => {
  const n = a.forwardRef((r, o) => {
    const { asChild: i, ...c } = r, s = i ? re : t;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ m.jsx(s, { ...c, ref: o });
  });
  return n.displayName = `Primitive.${t}`, { ...e, [t]: n };
}, {});
function Ct(e, t) {
  e && rt.flushSync(() => e.dispatchEvent(t));
}
function oe(e) {
  const t = a.useRef(e);
  return a.useEffect(() => {
    t.current = e;
  }), a.useMemo(() => (...n) => {
    var r;
    return (r = t.current) == null ? void 0 : r.call(t, ...n);
  }, []);
}
function St(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = oe(e);
  a.useEffect(() => {
    const r = (o) => {
      o.key === "Escape" && n(o);
    };
    return t.addEventListener("keydown", r, { capture: !0 }), () => t.removeEventListener("keydown", r, { capture: !0 });
  }, [n, t]);
}
var wt = "DismissableLayer", te = "dismissableLayer.update", Rt = "dismissableLayer.pointerDownOutside", Pt = "dismissableLayer.focusOutside", le, De = a.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), xe = a.forwardRef(
  (e, t) => {
    const {
      disableOutsidePointerEvents: n = !1,
      onEscapeKeyDown: r,
      onPointerDownOutside: o,
      onFocusOutside: i,
      onInteractOutside: c,
      onDismiss: s,
      ...d
    } = e, l = a.useContext(De), [f, h] = a.useState(null), E = (f == null ? void 0 : f.ownerDocument) ?? (globalThis == null ? void 0 : globalThis.document), [, g] = a.useState({}), w = I(t, (y) => h(y)), u = Array.from(l.layers), [v] = [...l.layersWithOutsidePointerEventsDisabled].slice(-1), p = u.indexOf(v), R = f ? u.indexOf(f) : -1, b = l.layersWithOutsidePointerEventsDisabled.size > 0, C = R >= p, S = xt((y) => {
      const D = y.target, k = [...l.branches].some((Y) => Y.contains(D));
      !C || k || (o == null || o(y), c == null || c(y), y.defaultPrevented || s == null || s());
    }, E), N = Ot((y) => {
      const D = y.target;
      [...l.branches].some((Y) => Y.contains(D)) || (i == null || i(y), c == null || c(y), y.defaultPrevented || s == null || s());
    }, E);
    return St((y) => {
      R === l.layers.size - 1 && (r == null || r(y), !y.defaultPrevented && s && (y.preventDefault(), s()));
    }, E), a.useEffect(() => {
      if (f)
        return n && (l.layersWithOutsidePointerEventsDisabled.size === 0 && (le = E.body.style.pointerEvents, E.body.style.pointerEvents = "none"), l.layersWithOutsidePointerEventsDisabled.add(f)), l.layers.add(f), de(), () => {
          n && l.layersWithOutsidePointerEventsDisabled.size === 1 && (E.body.style.pointerEvents = le);
        };
    }, [f, E, n, l]), a.useEffect(() => () => {
      f && (l.layers.delete(f), l.layersWithOutsidePointerEventsDisabled.delete(f), de());
    }, [f, l]), a.useEffect(() => {
      const y = () => g({});
      return document.addEventListener(te, y), () => document.removeEventListener(te, y);
    }, []), /* @__PURE__ */ m.jsx(
      x.div,
      {
        ...d,
        ref: w,
        style: {
          pointerEvents: b ? C ? "auto" : "none" : void 0,
          ...e.style
        },
        onFocusCapture: A(e.onFocusCapture, N.onFocusCapture),
        onBlurCapture: A(e.onBlurCapture, N.onBlurCapture),
        onPointerDownCapture: A(
          e.onPointerDownCapture,
          S.onPointerDownCapture
        )
      }
    );
  }
);
xe.displayName = wt;
var Nt = "DismissableLayerBranch", Dt = a.forwardRef((e, t) => {
  const n = a.useContext(De), r = a.useRef(null), o = I(t, r);
  return a.useEffect(() => {
    const i = r.current;
    if (i)
      return n.branches.add(i), () => {
        n.branches.delete(i);
      };
  }, [n.branches]), /* @__PURE__ */ m.jsx(x.div, { ...e, ref: o });
});
Dt.displayName = Nt;
function xt(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = oe(e), r = a.useRef(!1), o = a.useRef(() => {
  });
  return a.useEffect(() => {
    const i = (s) => {
      if (s.target && !r.current) {
        let d = function() {
          Oe(
            Rt,
            n,
            l,
            { discrete: !0 }
          );
        };
        const l = { originalEvent: s };
        s.pointerType === "touch" ? (t.removeEventListener("click", o.current), o.current = d, t.addEventListener("click", o.current, { once: !0 })) : d();
      } else
        t.removeEventListener("click", o.current);
      r.current = !1;
    }, c = window.setTimeout(() => {
      t.addEventListener("pointerdown", i);
    }, 0);
    return () => {
      window.clearTimeout(c), t.removeEventListener("pointerdown", i), t.removeEventListener("click", o.current);
    };
  }, [t, n]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => r.current = !0
  };
}
function Ot(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = oe(e), r = a.useRef(!1);
  return a.useEffect(() => {
    const o = (i) => {
      i.target && !r.current && Oe(Pt, n, { originalEvent: i }, {
        discrete: !1
      });
    };
    return t.addEventListener("focusin", o), () => t.removeEventListener("focusin", o);
  }, [t, n]), {
    onFocusCapture: () => r.current = !0,
    onBlurCapture: () => r.current = !1
  };
}
function de() {
  const e = new CustomEvent(te);
  document.dispatchEvent(e);
}
function Oe(e, t, n, { discrete: r }) {
  const o = n.originalEvent.target, i = new CustomEvent(e, { bubbles: !1, cancelable: !0, detail: n });
  t && o.addEventListener(e, t, { once: !0 }), r ? Ct(o, i) : o.dispatchEvent(i);
}
function fe(e) {
  const t = a.useRef(e);
  return a.useEffect(() => {
    t.current = e;
  }), a.useMemo(() => (...n) => {
    var r;
    return (r = t.current) == null ? void 0 : r.call(t, ...n);
  }, []);
}
var X = "focusScope.autoFocusOnMount", z = "focusScope.autoFocusOnUnmount", ve = { bubbles: !1, cancelable: !0 }, Tt = "FocusScope", Te = a.forwardRef((e, t) => {
  const {
    loop: n = !1,
    trapped: r = !1,
    onMountAutoFocus: o,
    onUnmountAutoFocus: i,
    ...c
  } = e, [s, d] = a.useState(null), l = fe(o), f = fe(i), h = a.useRef(null), E = I(t, (u) => d(u)), g = a.useRef({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  a.useEffect(() => {
    if (r) {
      let u = function(b) {
        if (g.paused || !s) return;
        const C = b.target;
        s.contains(C) ? h.current = C : O(h.current, { select: !0 });
      }, v = function(b) {
        if (g.paused || !s) return;
        const C = b.relatedTarget;
        C !== null && (s.contains(C) || O(h.current, { select: !0 }));
      }, p = function(b) {
        if (document.activeElement === document.body)
          for (const S of b)
            S.removedNodes.length > 0 && O(s);
      };
      document.addEventListener("focusin", u), document.addEventListener("focusout", v);
      const R = new MutationObserver(p);
      return s && R.observe(s, { childList: !0, subtree: !0 }), () => {
        document.removeEventListener("focusin", u), document.removeEventListener("focusout", v), R.disconnect();
      };
    }
  }, [r, s, g.paused]), a.useEffect(() => {
    if (s) {
      he.add(g);
      const u = document.activeElement;
      if (!s.contains(u)) {
        const p = new CustomEvent(X, ve);
        s.addEventListener(X, l), s.dispatchEvent(p), p.defaultPrevented || (At(_t(Ae(s)), { select: !0 }), document.activeElement === u && O(s));
      }
      return () => {
        s.removeEventListener(X, l), setTimeout(() => {
          const p = new CustomEvent(z, ve);
          s.addEventListener(z, f), s.dispatchEvent(p), p.defaultPrevented || O(u ?? document.body, { select: !0 }), s.removeEventListener(z, f), he.remove(g);
        }, 0);
      };
    }
  }, [s, l, f, g]);
  const w = a.useCallback(
    (u) => {
      if (!n && !r || g.paused) return;
      const v = u.key === "Tab" && !u.altKey && !u.ctrlKey && !u.metaKey, p = document.activeElement;
      if (v && p) {
        const R = u.currentTarget, [b, C] = Mt(R);
        b && C ? !u.shiftKey && p === C ? (u.preventDefault(), n && O(b, { select: !0 })) : u.shiftKey && p === b && (u.preventDefault(), n && O(C, { select: !0 })) : p === R && u.preventDefault();
      }
    },
    [n, r, g.paused]
  );
  return /* @__PURE__ */ m.jsx(x.div, { tabIndex: -1, ...c, ref: E, onKeyDown: w });
});
Te.displayName = Tt;
function At(e, { select: t = !1 } = {}) {
  const n = document.activeElement;
  for (const r of e)
    if (O(r, { select: t }), document.activeElement !== n) return;
}
function Mt(e) {
  const t = Ae(e), n = me(t, e), r = me(t.reverse(), e);
  return [n, r];
}
function Ae(e) {
  const t = [], n = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (r) => {
      const o = r.tagName === "INPUT" && r.type === "hidden";
      return r.disabled || r.hidden || o ? NodeFilter.FILTER_SKIP : r.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; n.nextNode(); ) t.push(n.currentNode);
  return t;
}
function me(e, t) {
  for (const n of e)
    if (!It(n, { upTo: t })) return n;
}
function It(e, { upTo: t }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (t !== void 0 && e === t) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function Lt(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function O(e, { select: t = !1 } = {}) {
  if (e && e.focus) {
    const n = document.activeElement;
    e.focus({ preventScroll: !0 }), e !== n && Lt(e) && t && e.select();
  }
}
var he = Ft();
function Ft() {
  let e = [];
  return {
    add(t) {
      const n = e[0];
      t !== n && (n == null || n.pause()), e = ge(e, t), e.unshift(t);
    },
    remove(t) {
      var n;
      e = ge(e, t), (n = e[0]) == null || n.resume();
    }
  };
}
function ge(e, t) {
  const n = [...e], r = n.indexOf(t);
  return r !== -1 && n.splice(r, 1), n;
}
function _t(e) {
  return e.filter((t) => t.tagName !== "A");
}
var kt = globalThis != null && globalThis.document ? a.useLayoutEffect : () => {
}, Wt = "Portal", Me = a.forwardRef((e, t) => {
  var s;
  const { container: n, ...r } = e, [o, i] = a.useState(!1);
  kt(() => i(!0), []);
  const c = n || o && ((s = globalThis == null ? void 0 : globalThis.document) == null ? void 0 : s.body);
  return c ? ot.createPortal(/* @__PURE__ */ m.jsx(x.div, { ...r, ref: t }), c) : null;
});
Me.displayName = Wt;
var pe = globalThis != null && globalThis.document ? a.useLayoutEffect : () => {
};
function jt(e, t) {
  return a.useReducer((n, r) => t[n][r] ?? n, e);
}
var K = (e) => {
  const { present: t, children: n } = e, r = Bt(t), o = typeof n == "function" ? n({ present: r.isPresent }) : a.Children.only(n), i = I(r.ref, Ut(o));
  return typeof n == "function" || r.isPresent ? a.cloneElement(o, { ref: i }) : null;
};
K.displayName = "Presence";
function Bt(e) {
  const [t, n] = a.useState(), r = a.useRef({}), o = a.useRef(e), i = a.useRef("none"), c = e ? "mounted" : "unmounted", [s, d] = jt(c, {
    mounted: {
      UNMOUNT: "unmounted",
      ANIMATION_OUT: "unmountSuspended"
    },
    unmountSuspended: {
      MOUNT: "mounted",
      ANIMATION_END: "unmounted"
    },
    unmounted: {
      MOUNT: "mounted"
    }
  });
  return a.useEffect(() => {
    const l = W(r.current);
    i.current = s === "mounted" ? l : "none";
  }, [s]), pe(() => {
    const l = r.current, f = o.current;
    if (f !== e) {
      const E = i.current, g = W(l);
      e ? d("MOUNT") : g === "none" || (l == null ? void 0 : l.display) === "none" ? d("UNMOUNT") : d(f && E !== g ? "ANIMATION_OUT" : "UNMOUNT"), o.current = e;
    }
  }, [e, d]), pe(() => {
    if (t) {
      let l;
      const f = t.ownerDocument.defaultView ?? window, h = (g) => {
        const u = W(r.current).includes(g.animationName);
        if (g.target === t && u && (d("ANIMATION_END"), !o.current)) {
          const v = t.style.animationFillMode;
          t.style.animationFillMode = "forwards", l = f.setTimeout(() => {
            t.style.animationFillMode === "forwards" && (t.style.animationFillMode = v);
          });
        }
      }, E = (g) => {
        g.target === t && (i.current = W(r.current));
      };
      return t.addEventListener("animationstart", E), t.addEventListener("animationcancel", h), t.addEventListener("animationend", h), () => {
        f.clearTimeout(l), t.removeEventListener("animationstart", E), t.removeEventListener("animationcancel", h), t.removeEventListener("animationend", h);
      };
    } else
      d("ANIMATION_END");
  }, [t, d]), {
    isPresent: ["mounted", "unmountSuspended"].includes(s),
    ref: a.useCallback((l) => {
      l && (r.current = getComputedStyle(l)), n(l);
    }, [])
  };
}
function W(e) {
  return (e == null ? void 0 : e.animationName) || "none";
}
function Ut(e) {
  var r, o;
  let t = (r = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : r.get, n = t && "isReactWarning" in t && t.isReactWarning;
  return n ? e.ref : (t = (o = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : o.get, n = t && "isReactWarning" in t && t.isReactWarning, n ? e.props.ref : e.props.ref || e.ref);
}
var Z = 0;
function $t() {
  a.useEffect(() => {
    const e = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", e[0] ?? Ee()), document.body.insertAdjacentElement("beforeend", e[1] ?? Ee()), Z++, () => {
      Z === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach((t) => t.remove()), Z--;
    };
  }, []);
}
function Ee() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.outline = "none", e.style.opacity = "0", e.style.position = "fixed", e.style.pointerEvents = "none", e;
}
var U = "right-scroll-bar-position", $ = "width-before-scroll-bar", Vt = "with-scroll-bars-hidden", Kt = "--removed-body-scroll-bar-size";
function q(e, t) {
  return typeof e == "function" ? e(t) : e && (e.current = t), e;
}
function Gt(e, t) {
  var n = nt(function() {
    return {
      // value
      value: e,
      // last callback
      callback: t,
      // "memoized" public interface
      facade: {
        get current() {
          return n.value;
        },
        set current(r) {
          var o = n.value;
          o !== r && (n.value = r, n.callback(r, o));
        }
      }
    };
  })[0];
  return n.callback = t, n.facade;
}
var Yt = typeof window < "u" ? a.useLayoutEffect : a.useEffect, ye = /* @__PURE__ */ new WeakMap();
function Ht(e, t) {
  var n = Gt(null, function(r) {
    return e.forEach(function(o) {
      return q(o, r);
    });
  });
  return Yt(function() {
    var r = ye.get(n);
    if (r) {
      var o = new Set(r), i = new Set(e), c = n.current;
      o.forEach(function(s) {
        i.has(s) || q(s, null);
      }), i.forEach(function(s) {
        o.has(s) || q(s, c);
      });
    }
    ye.set(n, e);
  }, [e]), n;
}
function Xt(e) {
  return e;
}
function zt(e, t) {
  t === void 0 && (t = Xt);
  var n = [], r = !1, o = {
    read: function() {
      if (r)
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      return n.length ? n[n.length - 1] : e;
    },
    useMedium: function(i) {
      var c = t(i, r);
      return n.push(c), function() {
        n = n.filter(function(s) {
          return s !== c;
        });
      };
    },
    assignSyncMedium: function(i) {
      for (r = !0; n.length; ) {
        var c = n;
        n = [], c.forEach(i);
      }
      n = {
        push: function(s) {
          return i(s);
        },
        filter: function() {
          return n;
        }
      };
    },
    assignMedium: function(i) {
      r = !0;
      var c = [];
      if (n.length) {
        var s = n;
        n = [], s.forEach(i), c = n;
      }
      var d = function() {
        var f = c;
        c = [], f.forEach(i);
      }, l = function() {
        return Promise.resolve().then(d);
      };
      l(), n = {
        push: function(f) {
          c.push(f), l();
        },
        filter: function(f) {
          return c = c.filter(f), n;
        }
      };
    }
  };
  return o;
}
function Zt(e) {
  e === void 0 && (e = {});
  var t = zt(null);
  return t.options = T({ async: !0, ssr: !1 }, e), t;
}
var Ie = function(e) {
  var t = e.sideCar, n = Re(e, ["sideCar"]);
  if (!t)
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  var r = t.read();
  if (!r)
    throw new Error("Sidecar medium not found");
  return a.createElement(r, T({}, n));
};
Ie.isSideCarExport = !0;
function qt(e, t) {
  return e.useMedium(t), Ie;
}
var Le = Zt(), Q = function() {
}, G = a.forwardRef(function(e, t) {
  var n = a.useRef(null), r = a.useState({
    onScrollCapture: Q,
    onWheelCapture: Q,
    onTouchMoveCapture: Q
  }), o = r[0], i = r[1], c = e.forwardProps, s = e.children, d = e.className, l = e.removeScrollBar, f = e.enabled, h = e.shards, E = e.sideCar, g = e.noIsolation, w = e.inert, u = e.allowPinchZoom, v = e.as, p = v === void 0 ? "div" : v, R = e.gapMode, b = Re(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as", "gapMode"]), C = E, S = Ht([n, t]), N = T(T({}, b), o);
  return a.createElement(
    a.Fragment,
    null,
    f && a.createElement(C, { sideCar: Le, removeScrollBar: l, shards: h, noIsolation: g, inert: w, setCallbacks: i, allowPinchZoom: !!u, lockRef: n, gapMode: R }),
    c ? a.cloneElement(a.Children.only(s), T(T({}, N), { ref: S })) : a.createElement(p, T({}, N, { className: d, ref: S }), s)
  );
});
G.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
G.classNames = {
  fullWidth: $,
  zeroRight: U
};
function Qt() {
  if (!document)
    return null;
  var e = document.createElement("style");
  e.type = "text/css";
  var t = it();
  return t && e.setAttribute("nonce", t), e;
}
function Jt(e, t) {
  e.styleSheet ? e.styleSheet.cssText = t : e.appendChild(document.createTextNode(t));
}
function en(e) {
  var t = document.head || document.getElementsByTagName("head")[0];
  t.appendChild(e);
}
var tn = function() {
  var e = 0, t = null;
  return {
    add: function(n) {
      e == 0 && (t = Qt()) && (Jt(t, n), en(t)), e++;
    },
    remove: function() {
      e--, !e && t && (t.parentNode && t.parentNode.removeChild(t), t = null);
    }
  };
}, nn = function() {
  var e = tn();
  return function(t, n) {
    a.useEffect(function() {
      return e.add(t), function() {
        e.remove();
      };
    }, [t && n]);
  };
}, Fe = function() {
  var e = nn(), t = function(n) {
    var r = n.styles, o = n.dynamic;
    return e(r, o), null;
  };
  return t;
}, rn = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
}, J = function(e) {
  return parseInt(e || "", 10) || 0;
}, on = function(e) {
  var t = window.getComputedStyle(document.body), n = t[e === "padding" ? "paddingLeft" : "marginLeft"], r = t[e === "padding" ? "paddingTop" : "marginTop"], o = t[e === "padding" ? "paddingRight" : "marginRight"];
  return [J(n), J(r), J(o)];
}, an = function(e) {
  if (e === void 0 && (e = "margin"), typeof window > "u")
    return rn;
  var t = on(e), n = document.documentElement.clientWidth, r = window.innerWidth;
  return {
    left: t[0],
    top: t[1],
    right: t[2],
    gap: Math.max(0, r - n + t[2] - t[0])
  };
}, sn = Fe(), _ = "data-scroll-locked", cn = function(e, t, n, r) {
  var o = e.left, i = e.top, c = e.right, s = e.gap;
  return n === void 0 && (n = "margin"), `
  .`.concat(Vt, ` {
   overflow: hidden `).concat(r, `;
   padding-right: `).concat(s, "px ").concat(r, `;
  }
  body[`).concat(_, `] {
    overflow: hidden `).concat(r, `;
    overscroll-behavior: contain;
    `).concat([
    t && "position: relative ".concat(r, ";"),
    n === "margin" && `
    padding-left: `.concat(o, `px;
    padding-top: `).concat(i, `px;
    padding-right: `).concat(c, `px;
    margin-left:0;
    margin-top:0;
    margin-right: `).concat(s, "px ").concat(r, `;
    `),
    n === "padding" && "padding-right: ".concat(s, "px ").concat(r, ";")
  ].filter(Boolean).join(""), `
  }
  
  .`).concat(U, ` {
    right: `).concat(s, "px ").concat(r, `;
  }
  
  .`).concat($, ` {
    margin-right: `).concat(s, "px ").concat(r, `;
  }
  
  .`).concat(U, " .").concat(U, ` {
    right: 0 `).concat(r, `;
  }
  
  .`).concat($, " .").concat($, ` {
    margin-right: 0 `).concat(r, `;
  }
  
  body[`).concat(_, `] {
    `).concat(Kt, ": ").concat(s, `px;
  }
`);
}, be = function() {
  var e = parseInt(document.body.getAttribute(_) || "0", 10);
  return isFinite(e) ? e : 0;
}, un = function() {
  a.useEffect(function() {
    return document.body.setAttribute(_, (be() + 1).toString()), function() {
      var e = be() - 1;
      e <= 0 ? document.body.removeAttribute(_) : document.body.setAttribute(_, e.toString());
    };
  }, []);
}, ln = function(e) {
  var t = e.noRelative, n = e.noImportant, r = e.gapMode, o = r === void 0 ? "margin" : r;
  un();
  var i = a.useMemo(function() {
    return an(o);
  }, [o]);
  return a.createElement(sn, { styles: cn(i, !t, o, n ? "" : "!important") });
}, ne = !1;
if (typeof window < "u")
  try {
    var j = Object.defineProperty({}, "passive", {
      get: function() {
        return ne = !0, !0;
      }
    });
    window.addEventListener("test", j, j), window.removeEventListener("test", j, j);
  } catch {
    ne = !1;
  }
var L = ne ? { passive: !1 } : !1, dn = function(e) {
  return e.tagName === "TEXTAREA";
}, _e = function(e, t) {
  if (!(e instanceof Element))
    return !1;
  var n = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    n[t] !== "hidden" && // contains scroll inside self
    !(n.overflowY === n.overflowX && !dn(e) && n[t] === "visible")
  );
}, fn = function(e) {
  return _e(e, "overflowY");
}, vn = function(e) {
  return _e(e, "overflowX");
}, Ce = function(e, t) {
  var n = t.ownerDocument, r = t;
  do {
    typeof ShadowRoot < "u" && r instanceof ShadowRoot && (r = r.host);
    var o = ke(e, r);
    if (o) {
      var i = We(e, r), c = i[1], s = i[2];
      if (c > s)
        return !0;
    }
    r = r.parentNode;
  } while (r && r !== n.body);
  return !1;
}, mn = function(e) {
  var t = e.scrollTop, n = e.scrollHeight, r = e.clientHeight;
  return [
    t,
    n,
    r
  ];
}, hn = function(e) {
  var t = e.scrollLeft, n = e.scrollWidth, r = e.clientWidth;
  return [
    t,
    n,
    r
  ];
}, ke = function(e, t) {
  return e === "v" ? fn(t) : vn(t);
}, We = function(e, t) {
  return e === "v" ? mn(t) : hn(t);
}, gn = function(e, t) {
  return e === "h" && t === "rtl" ? -1 : 1;
}, pn = function(e, t, n, r, o) {
  var i = gn(e, window.getComputedStyle(t).direction), c = i * r, s = n.target, d = t.contains(s), l = !1, f = c > 0, h = 0, E = 0;
  do {
    var g = We(e, s), w = g[0], u = g[1], v = g[2], p = u - v - i * w;
    (w || p) && ke(e, s) && (h += p, E += w), s instanceof ShadowRoot ? s = s.host : s = s.parentNode;
  } while (
    // portaled content
    !d && s !== document.body || // self content
    d && (t.contains(s) || t === s)
  );
  return (f && Math.abs(h) < 1 || !f && Math.abs(E) < 1) && (l = !0), l;
}, B = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, Se = function(e) {
  return [e.deltaX, e.deltaY];
}, we = function(e) {
  return e && "current" in e ? e.current : e;
}, En = function(e, t) {
  return e[0] === t[0] && e[1] === t[1];
}, yn = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, bn = 0, F = [];
function Cn(e) {
  var t = a.useRef([]), n = a.useRef([0, 0]), r = a.useRef(), o = a.useState(bn++)[0], i = a.useState(Fe)[0], c = a.useRef(e);
  a.useEffect(function() {
    c.current = e;
  }, [e]), a.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(o));
      var u = at([e.lockRef.current], (e.shards || []).map(we), !0).filter(Boolean);
      return u.forEach(function(v) {
        return v.classList.add("allow-interactivity-".concat(o));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(o)), u.forEach(function(v) {
          return v.classList.remove("allow-interactivity-".concat(o));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var s = a.useCallback(function(u, v) {
    if ("touches" in u && u.touches.length === 2 || u.type === "wheel" && u.ctrlKey)
      return !c.current.allowPinchZoom;
    var p = B(u), R = n.current, b = "deltaX" in u ? u.deltaX : R[0] - p[0], C = "deltaY" in u ? u.deltaY : R[1] - p[1], S, N = u.target, y = Math.abs(b) > Math.abs(C) ? "h" : "v";
    if ("touches" in u && y === "h" && N.type === "range")
      return !1;
    var D = Ce(y, N);
    if (!D)
      return !0;
    if (D ? S = y : (S = y === "v" ? "h" : "v", D = Ce(y, N)), !D)
      return !1;
    if (!r.current && "changedTouches" in u && (b || C) && (r.current = S), !S)
      return !0;
    var k = r.current || S;
    return pn(k, v, u, k === "h" ? b : C);
  }, []), d = a.useCallback(function(u) {
    var v = u;
    if (!(!F.length || F[F.length - 1] !== i)) {
      var p = "deltaY" in v ? Se(v) : B(v), R = t.current.filter(function(S) {
        return S.name === v.type && (S.target === v.target || v.target === S.shadowParent) && En(S.delta, p);
      })[0];
      if (R && R.should) {
        v.cancelable && v.preventDefault();
        return;
      }
      if (!R) {
        var b = (c.current.shards || []).map(we).filter(Boolean).filter(function(S) {
          return S.contains(v.target);
        }), C = b.length > 0 ? s(v, b[0]) : !c.current.noIsolation;
        C && v.cancelable && v.preventDefault();
      }
    }
  }, []), l = a.useCallback(function(u, v, p, R) {
    var b = { name: u, delta: v, target: p, should: R, shadowParent: Sn(p) };
    t.current.push(b), setTimeout(function() {
      t.current = t.current.filter(function(C) {
        return C !== b;
      });
    }, 1);
  }, []), f = a.useCallback(function(u) {
    n.current = B(u), r.current = void 0;
  }, []), h = a.useCallback(function(u) {
    l(u.type, Se(u), u.target, s(u, e.lockRef.current));
  }, []), E = a.useCallback(function(u) {
    l(u.type, B(u), u.target, s(u, e.lockRef.current));
  }, []);
  a.useEffect(function() {
    return F.push(i), e.setCallbacks({
      onScrollCapture: h,
      onWheelCapture: h,
      onTouchMoveCapture: E
    }), document.addEventListener("wheel", d, L), document.addEventListener("touchmove", d, L), document.addEventListener("touchstart", f, L), function() {
      F = F.filter(function(u) {
        return u !== i;
      }), document.removeEventListener("wheel", d, L), document.removeEventListener("touchmove", d, L), document.removeEventListener("touchstart", f, L);
    };
  }, []);
  var g = e.removeScrollBar, w = e.inert;
  return a.createElement(
    a.Fragment,
    null,
    w ? a.createElement(i, { styles: yn(o) }) : null,
    g ? a.createElement(ln, { gapMode: e.gapMode }) : null
  );
}
function Sn(e) {
  for (var t = null; e !== null; )
    e instanceof ShadowRoot && (t = e.host, e = e.host), e = e.parentNode;
  return t;
}
const wn = qt(Le, Cn);
var je = a.forwardRef(function(e, t) {
  return a.createElement(G, T({}, e, { ref: t, sideCar: wn }));
});
je.classNames = G.classNames;
var ae = "Dialog", [Be, kn] = ut(ae), [Rn, P] = Be(ae), Ue = (e) => {
  const {
    __scopeDialog: t,
    children: n,
    open: r,
    defaultOpen: o,
    onOpenChange: i,
    modal: c = !0
  } = e, s = a.useRef(null), d = a.useRef(null), [l = !1, f] = mt({
    prop: r,
    defaultProp: o,
    onChange: i
  });
  return /* @__PURE__ */ m.jsx(
    Rn,
    {
      scope: t,
      triggerRef: s,
      contentRef: d,
      contentId: H(),
      titleId: H(),
      descriptionId: H(),
      open: l,
      onOpenChange: f,
      onOpenToggle: a.useCallback(() => f((h) => !h), [f]),
      modal: c,
      children: n
    }
  );
};
Ue.displayName = ae;
var $e = "DialogTrigger", Ve = a.forwardRef(
  (e, t) => {
    const { __scopeDialog: n, ...r } = e, o = P($e, n), i = I(t, o.triggerRef);
    return /* @__PURE__ */ m.jsx(
      x.button,
      {
        type: "button",
        "aria-haspopup": "dialog",
        "aria-expanded": o.open,
        "aria-controls": o.contentId,
        "data-state": ce(o.open),
        ...r,
        ref: i,
        onClick: A(e.onClick, o.onOpenToggle)
      }
    );
  }
);
Ve.displayName = $e;
var ie = "DialogPortal", [Pn, Ke] = Be(ie, {
  forceMount: void 0
}), Ge = (e) => {
  const { __scopeDialog: t, forceMount: n, children: r, container: o } = e, i = P(ie, t);
  return /* @__PURE__ */ m.jsx(Pn, { scope: t, forceMount: n, children: a.Children.map(r, (c) => /* @__PURE__ */ m.jsx(K, { present: n || i.open, children: /* @__PURE__ */ m.jsx(Me, { asChild: !0, container: o, children: c }) })) });
};
Ge.displayName = ie;
var V = "DialogOverlay", Ye = a.forwardRef(
  (e, t) => {
    const n = Ke(V, e.__scopeDialog), { forceMount: r = n.forceMount, ...o } = e, i = P(V, e.__scopeDialog);
    return i.modal ? /* @__PURE__ */ m.jsx(K, { present: r || i.open, children: /* @__PURE__ */ m.jsx(Nn, { ...o, ref: t }) }) : null;
  }
);
Ye.displayName = V;
var Nn = a.forwardRef(
  (e, t) => {
    const { __scopeDialog: n, ...r } = e, o = P(V, n);
    return (
      // Make sure `Content` is scrollable even when it doesn't live inside `RemoveScroll`
      // ie. when `Overlay` and `Content` are siblings
      /* @__PURE__ */ m.jsx(je, { as: re, allowPinchZoom: !0, shards: [o.contentRef], children: /* @__PURE__ */ m.jsx(
        x.div,
        {
          "data-state": ce(o.open),
          ...r,
          ref: t,
          style: { pointerEvents: "auto", ...r.style }
        }
      ) })
    );
  }
), M = "DialogContent", He = a.forwardRef(
  (e, t) => {
    const n = Ke(M, e.__scopeDialog), { forceMount: r = n.forceMount, ...o } = e, i = P(M, e.__scopeDialog);
    return /* @__PURE__ */ m.jsx(K, { present: r || i.open, children: i.modal ? /* @__PURE__ */ m.jsx(Dn, { ...o, ref: t }) : /* @__PURE__ */ m.jsx(xn, { ...o, ref: t }) });
  }
);
He.displayName = M;
var Dn = a.forwardRef(
  (e, t) => {
    const n = P(M, e.__scopeDialog), r = a.useRef(null), o = I(t, n.contentRef, r);
    return a.useEffect(() => {
      const i = r.current;
      if (i) return st(i);
    }, []), /* @__PURE__ */ m.jsx(
      Xe,
      {
        ...e,
        ref: o,
        trapFocus: n.open,
        disableOutsidePointerEvents: !0,
        onCloseAutoFocus: A(e.onCloseAutoFocus, (i) => {
          var c;
          i.preventDefault(), (c = n.triggerRef.current) == null || c.focus();
        }),
        onPointerDownOutside: A(e.onPointerDownOutside, (i) => {
          const c = i.detail.originalEvent, s = c.button === 0 && c.ctrlKey === !0;
          (c.button === 2 || s) && i.preventDefault();
        }),
        onFocusOutside: A(
          e.onFocusOutside,
          (i) => i.preventDefault()
        )
      }
    );
  }
), xn = a.forwardRef(
  (e, t) => {
    const n = P(M, e.__scopeDialog), r = a.useRef(!1), o = a.useRef(!1);
    return /* @__PURE__ */ m.jsx(
      Xe,
      {
        ...e,
        ref: t,
        trapFocus: !1,
        disableOutsidePointerEvents: !1,
        onCloseAutoFocus: (i) => {
          var c, s;
          (c = e.onCloseAutoFocus) == null || c.call(e, i), i.defaultPrevented || (r.current || (s = n.triggerRef.current) == null || s.focus(), i.preventDefault()), r.current = !1, o.current = !1;
        },
        onInteractOutside: (i) => {
          var d, l;
          (d = e.onInteractOutside) == null || d.call(e, i), i.defaultPrevented || (r.current = !0, i.detail.originalEvent.type === "pointerdown" && (o.current = !0));
          const c = i.target;
          ((l = n.triggerRef.current) == null ? void 0 : l.contains(c)) && i.preventDefault(), i.detail.originalEvent.type === "focusin" && o.current && i.preventDefault();
        }
      }
    );
  }
), Xe = a.forwardRef(
  (e, t) => {
    const { __scopeDialog: n, trapFocus: r, onOpenAutoFocus: o, onCloseAutoFocus: i, ...c } = e, s = P(M, n), d = a.useRef(null), l = I(t, d);
    return $t(), /* @__PURE__ */ m.jsxs(m.Fragment, { children: [
      /* @__PURE__ */ m.jsx(
        Te,
        {
          asChild: !0,
          loop: !0,
          trapped: r,
          onMountAutoFocus: o,
          onUnmountAutoFocus: i,
          children: /* @__PURE__ */ m.jsx(
            xe,
            {
              role: "dialog",
              id: s.contentId,
              "aria-describedby": s.descriptionId,
              "aria-labelledby": s.titleId,
              "data-state": ce(s.open),
              ...c,
              ref: l,
              onDismiss: () => s.onOpenChange(!1)
            }
          )
        }
      ),
      /* @__PURE__ */ m.jsxs(m.Fragment, { children: [
        /* @__PURE__ */ m.jsx(On, { titleId: s.titleId }),
        /* @__PURE__ */ m.jsx(An, { contentRef: d, descriptionId: s.descriptionId })
      ] })
    ] });
  }
), se = "DialogTitle", ze = a.forwardRef(
  (e, t) => {
    const { __scopeDialog: n, ...r } = e, o = P(se, n);
    return /* @__PURE__ */ m.jsx(x.h2, { id: o.titleId, ...r, ref: t });
  }
);
ze.displayName = se;
var Ze = "DialogDescription", qe = a.forwardRef(
  (e, t) => {
    const { __scopeDialog: n, ...r } = e, o = P(Ze, n);
    return /* @__PURE__ */ m.jsx(x.p, { id: o.descriptionId, ...r, ref: t });
  }
);
qe.displayName = Ze;
var Qe = "DialogClose", Je = a.forwardRef(
  (e, t) => {
    const { __scopeDialog: n, ...r } = e, o = P(Qe, n);
    return /* @__PURE__ */ m.jsx(
      x.button,
      {
        type: "button",
        ...r,
        ref: t,
        onClick: A(e.onClick, () => o.onOpenChange(!1))
      }
    );
  }
);
Je.displayName = Qe;
function ce(e) {
  return e ? "open" : "closed";
}
var et = "DialogTitleWarning", [Wn, tt] = ct(et, {
  contentName: M,
  titleName: se,
  docsSlug: "dialog"
}), On = ({ titleId: e }) => {
  const t = tt(et), n = `\`${t.contentName}\` requires a \`${t.titleName}\` for the component to be accessible for screen reader users.

If you want to hide the \`${t.titleName}\`, you can wrap it with our VisuallyHidden component.

For more information, see https://radix-ui.com/primitives/docs/components/${t.docsSlug}`;
  return a.useEffect(() => {
    e && (document.getElementById(e) || console.error(n));
  }, [n, e]), null;
}, Tn = "DialogDescriptionWarning", An = ({ contentRef: e, descriptionId: t }) => {
  const r = `Warning: Missing \`Description\` or \`aria-describedby={undefined}\` for {${tt(Tn).contentName}}.`;
  return a.useEffect(() => {
    var i;
    const o = (i = e.current) == null ? void 0 : i.getAttribute("aria-describedby");
    t && o && (document.getElementById(t) || console.warn(r));
  }, [r, e, t]), null;
}, jn = Ue, Bn = Ve, Un = Ge, $n = Ye, Vn = He, Kn = ze, Gn = qe, Yn = Je;
export {
  Vn as C,
  Gn as D,
  $n as O,
  Un as P,
  jn as R,
  Bn as T,
  Kn as a,
  Yn as b
};
