import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Button from 'components/dist/atoms/Button';
import Text from 'components/dist/atoms/Text';
import NextLink from 'next/link';
import { useRouter } from "next/router";
import { SocialAuthButtons } from 'src/components/auth/social-auth-buttons';
import { Route } from "src/constants/routing";
import { useSubdomainLender } from "src/contexts/subdomain-lender-context";
import { themeV2 } from "src/theme/mysherpas-theme-option";

import { BackButton } from "../common/back-button";
import { CompanyLogo } from "./company-logo";

interface Props {
    onSignUpWithEmailClick?: () => void;
    title?: string;
    showLogo?: boolean;
    showBackButton?: boolean;
    showLoginLink?: boolean;
}


export const SignUpOptionsForm = (props: Props) => {
    const {
        onSignUpWithEmailClick,
        title = 'Register your account',
        showLogo = true,
        showBackButton = true,
        showLoginLink = true
    } = props;
    const { logo, name } = useSubdomainLender();
    const { query, push } = useRouter();

    return (<Box
        sx={{
            width: {
                xs: '100%',
                sm: 480,
            },
            alignItems: 'center',
            textAlign: 'center',
        }}>
        {showBackButton && (
            <Box sx={{
                position: 'fixed',
                top: {
                    xs: 16,
                    sm: 32,
                    md: 80
                },
                left: {
                    xs: 16,
                    sm: 32,
                    md: 64
                },
                zIndex: 2
            }}>
                <BackButton onClick={() => push({ pathname: Route.LOGIN, query })} />
            </Box>
        )}
        <div className="gap-6 flex flex-col">
            {showLogo && (<CompanyLogo logo={logo} name={name} />)}
            <Text size="xl" weight="medium">
                {title}
            </Text>
        </div>
        <SocialAuthButtons mode='register' />
        <Divider sx={{ width: '100%', my: 5 }}>
            OR
        </Divider>
        <Button
            onClick={onSignUpWithEmailClick}
            className="sm:w-full bg-blue-100 hover:bg-blue-60 mt-10 w-[90%]"
            data-testid='login-button'
            type='submit'>
            Sign up with Email
        </Button>
        {showLoginLink && (
            <Box sx={{
                gap: 1,
                mt: 5,
                display: 'flex',
                justifyContent: 'center',
            }}>
                Existing user?
                <NextLink
                    passHref
                    href={{ pathname: Route.LOGIN, query }}>
                    <Link sx={{
                        color: themeV2.colors.blue[100],
                    }}>
                        Log in
                    </Link>
                </NextLink>
            </Box>
        )}
    </Box>
    )
};
